import * as images2021 from './2021/images';
import * as images2020 from './2020/images';
import * as images2019 from './2019/images';
import * as images2018 from './2018/images';
import * as images2017 from './2017/images';
import * as images2016 from './2016/images';
import * as images2015 from './2015/images';

export default {
  2021: images2021,
  2020: images2020,
  2019: images2019,
  2018: images2018,
  2017: images2017,
  2016: images2016,
  2015: images2015,
};
