import React from 'react';


class Faq extends React.Component {

  render() {

  }

}

export default Faq;
