export default {
  "acton": "Acton is categorized by MAPC as a Established Suburb. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "arlington": "Arlington is categorized by MAPC as a Streetcar Suburb. These communities are characterized by village-oriented residential neighborhoods dominated by multifamily homes and smaller apartment buildings. New growth occurs through limited redevelopment and infill and expansion of existing structures. They have moderately diverse populations, stable or losing population due to decreasing household size.",
  "ashland": "Ashland is categorized by MAPC as a Established Suburb. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "bedford": "Bedford is categorized by MAPC as a Established Suburb. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "bellingham": "Bellingham is categorized by MAPC as a Maturing New England Town. These communities are characterized by a mixed-use town center surrounded by compact neighborhoods. They have a large amount of vacant developable land, and new growth comes in the form of new subdivisions. They are among the most rapidly growing communities in the Boston region.",
  "belmont": "Belmont is categorized by MAPC as a Streetcar Suburb. These communities are characterized by village-oriented residential neighborhoods dominated by multifamily homes and smaller apartment buildings. New growth occurs through limited redevelopment and infill and expansion of existing structures. They have moderately diverse populations, stable or losing population due to decreasing household size.",
  "beverly": "Beverly is categorized by MAPC as a Subregional Urban Center. These communities are characterized by urban-scale downtown core surrounded by more suburban residential neighborhoods. New growth takes the form of redevelopment in downtown or industrial areas, and greenfield development on the periphery.",
  "bolton": "Bolton is categorized by MAPC as a Country Suburb. These communities are characterized by very low density, with no significant town center and no compact neighborhoods. They have large amounts of vacant developable land, and new growth largely takes the form of conventional low-density subdivisions developed on vacant land. They are generally growing rapidly.",
  "boston": "Boston is categorized by MAPC as a Metropolitan Core Community. These communities are characterized by an urban environment containing a mix of apartment buildings, multifamily houses, single family houses. New growth mostly occurs through redevelopment, infill, or conversion from industrial uses to residential. The have large minority and immigrant populations.",
  "boxborough": "Boxborough is categorized by MAPC as a Country Suburb. These communities are characterized by very low density, with no significant town center and no compact neighborhoods. They have large amounts of vacant developable land, and new growth largely takes the form of conventional low-density subdivisions developed on vacant land. They are generally growing rapidly.",
  "braintree": "Braintree is categorized by MAPC as a Mature Suburban Town. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "brookline": "Brookline is categorized by MAPC as a Streetcar Suburb. These communities are characterized by village-oriented residential neighborhoods dominated by multifamily homes and smaller apartment buildings. New growth occurs through limited redevelopment and infill and expansion of existing structures. They have moderately diverse populations, stable or losing population due to decreasing household size.",
  "burlington": "Burlington is categorized by MAPC as a Mature Suburban Town. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "cambridge": "Cambridge is categorized by MAPC as a Metropolitan Core Community. These communities are characterized by an urban environment containing a mix of apartment buildings, multifamily houses, single family houses. New growth mostly occurs through redevelopment, infill, or conversion from industrial uses to residential. The have large minority and immigrant populations.",
  "canton": "Canton is categorized by MAPC as a Established Suburb. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "carlisle": "Carlisle is categorized by MAPC as a Country Suburb. These communities are characterized by very low density, with no significant town center and no compact neighborhoods. They have large amounts of vacant developable land, and new growth largely takes the form of conventional low-density subdivisions developed on vacant land. They are generally growing rapidly.",
  "chelsea": "Chelsea is categorized by MAPC as a Metropolitan Core Community. These communities are characterized by an urban environment containing a mix of apartment buildings, multifamily houses, single family houses. New growth mostly occurs through redevelopment, infill, or conversion from industrial uses to residential. The have large minority and immigrant populations.",
  "cohasset": "Cohasset is categorized by MAPC as a Country Suburb. These communities are characterized by very low density, with no significant town center and no compact neighborhoods. They have large amounts of vacant developable land, and new growth largely takes the form of conventional low-density subdivisions developed on vacant land. They are generally growing rapidly.",
  "concord": "Concord is categorized by MAPC as a Established Suburb. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "danvers": "Danvers is categorized by MAPC as a Mature Suburban Town. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "dedham": "Dedham is categorized by MAPC as a Mature Suburban Town. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "dover": "Dover is categorized by MAPC as a Country Suburb. These communities are characterized by very low density, with no significant town center and no compact neighborhoods. They have large amounts of vacant developable land, and new growth largely takes the form of conventional low-density subdivisions developed on vacant land. They are generally growing rapidly.",
  "duxbury": "Duxbury is categorized by MAPC as a Established Suburb. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "essex": "Essex is categorized by MAPC as a Maturing New England Town. These communities are characterized by a mixed-use town center surrounded by compact neighborhoods. They have a large amount of vacant developable land, and new growth comes in the form of new subdivisions. They are among the most rapidly growing communities in the Boston region.",
  "everett": "Everett is categorized by MAPC as a Metropolitan Core Community. These communities are characterized by an urban environment containing a mix of apartment buildings, multifamily houses, single family houses. New growth mostly occurs through redevelopment, infill, or conversion from industrial uses to residential. The have large minority and immigrant populations.",
  "foxborough": "Foxborough is categorized by MAPC as a Maturing New England Town. These communities are characterized by a mixed-use town center surrounded by compact neighborhoods. They have a large amount of vacant developable land, and new growth comes in the form of new subdivisions. They are among the most rapidly growing communities in the Boston region.",
  "framingham": "Framingham is categorized by MAPC as a Subregional Urban Center. These communities are characterized by urban-scale downtown core surrounded by more suburban residential neighborhoods. New growth takes the form of redevelopment in downtown or industrial areas, and greenfield development on the periphery.",
  "franklin": "Franklin is categorized by MAPC as a Maturing New England Town. These communities are characterized by a mixed-use town center surrounded by compact neighborhoods. They have a large amount of vacant developable land, and new growth comes in the form of new subdivisions. They are among the most rapidly growing communities in the Boston region.",
  "gloucester": "Gloucester is categorized by MAPC as a Subregional Urban Center. These communities are characterized by urban-scale downtown core surrounded by more suburban residential neighborhoods. New growth takes the form of redevelopment in downtown or industrial areas, and greenfield development on the periphery.",
  "hamilton": "Hamilton is categorized by MAPC as a Maturing New England Town. These communities are characterized by a mixed-use town center surrounded by compact neighborhoods. They have a large amount of vacant developable land, and new growth comes in the form of new subdivisions. They are among the most rapidly growing communities in the Boston region.",
  "hanover": "Hanover is categorized by MAPC as a Maturing New England Town. These communities are characterized by a mixed-use town center surrounded by compact neighborhoods. They have a large amount of vacant developable land, and new growth comes in the form of new subdivisions. They are among the most rapidly growing communities in the Boston region.",
  "hingham": "Hingham is categorized by MAPC as a Established Suburb. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "holbrook": "Holbrook is categorized by MAPC as a Mature Suburban Town. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "holliston": "Holliston is categorized by MAPC as a Maturing New England Town. These communities are characterized by a mixed-use town center surrounded by compact neighborhoods. They have a large amount of vacant developable land, and new growth comes in the form of new subdivisions. They are among the most rapidly growing communities in the Boston region.",
  "hopkinton": "Hopkinton is categorized by MAPC as a Country Suburb. These communities are characterized by very low density, with no significant town center and no compact neighborhoods. They have large amounts of vacant developable land, and new growth largely takes the form of conventional low-density subdivisions developed on vacant land. They are generally growing rapidly.",
  "hudson": "Hudson is categorized by MAPC as a Maturing New England Town. These communities are characterized by a mixed-use town center surrounded by compact neighborhoods. They have a large amount of vacant developable land, and new growth comes in the form of new subdivisions. They are among the most rapidly growing communities in the Boston region.",
  "hull": "Hull is categorized by MAPC as a Mature Suburban Town. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "ipswich": "Ipswich is categorized by MAPC as a Maturing New England Town. These communities are characterized by a mixed-use town center surrounded by compact neighborhoods. They have a large amount of vacant developable land, and new growth comes in the form of new subdivisions. They are among the most rapidly growing communities in the Boston region.",
  "lexington": "Lexington is categorized by MAPC as a Established Suburb. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "lincoln": "Lincoln is categorized by MAPC as a Established Suburb. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "littleton": "Littleton is categorized by MAPC as a Maturing New England Town. These communities are characterized by a mixed-use town center surrounded by compact neighborhoods. They have a large amount of vacant developable land, and new growth comes in the form of new subdivisions. They are among the most rapidly growing communities in the Boston region.",
  "lynn": "Lynn is categorized by MAPC as a Subregional Urban Center. These communities are characterized by urban-scale downtown core surrounded by more suburban residential neighborhoods. New growth takes the form of redevelopment in downtown or industrial areas, and greenfield development on the periphery.",
  "lynnfield": "Lynnfield is categorized by MAPC as a Established Suburb. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "malden": "Malden is categorized by MAPC as a Metropolitan Core Community. These communities are characterized by an urban environment containing a mix of apartment buildings, multifamily houses, single family houses. New growth mostly occurs through redevelopment, infill, or conversion from industrial uses to residential. The have large minority and immigrant populations.",
  "manchester": "Manchester is categorized by MAPC as a Maturing New England Town. These communities are characterized by a mixed-use town center surrounded by compact neighborhoods. They have a large amount of vacant developable land, and new growth comes in the form of new subdivisions. They are among the most rapidly growing communities in the Boston region.",
  "marblehead": "Marblehead is categorized by MAPC as a Mature Suburban Town. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "marlborough": "Marlborough is categorized by MAPC as a Subregional Urban Center. These communities are characterized by urban-scale downtown core surrounded by more suburban residential neighborhoods. New growth takes the form of redevelopment in downtown or industrial areas, and greenfield development on the periphery.",
  "marshfield": "Marshfield is categorized by MAPC as a Established Suburb. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "maynard": "Maynard is categorized by MAPC as a Mature Suburban Town. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "medfield": "Medfield is categorized by MAPC as a Established Suburb. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "medford": "Medford is categorized by MAPC as a Streetcar Suburb. These communities are characterized by village-oriented residential neighborhoods dominated by multifamily homes and smaller apartment buildings. New growth occurs through limited redevelopment and infill and expansion of existing structures. They have moderately diverse populations, stable or losing population due to decreasing household size.",
  "medway": "Medway is categorized by MAPC as a Maturing New England Town. These communities are characterized by a mixed-use town center surrounded by compact neighborhoods. They have a large amount of vacant developable land, and new growth comes in the form of new subdivisions. They are among the most rapidly growing communities in the Boston region.",
  "melrose": "Melrose is categorized by MAPC as a Streetcar Suburb. These communities are characterized by village-oriented residential neighborhoods dominated by multifamily homes and smaller apartment buildings. New growth occurs through limited redevelopment and infill and expansion of existing structures. They have moderately diverse populations, stable or losing population due to decreasing household size.",
  "middleton": "Middleton is categorized by MAPC as a Maturing New England Town. These communities are characterized by a mixed-use town center surrounded by compact neighborhoods. They have a large amount of vacant developable land, and new growth comes in the form of new subdivisions. They are among the most rapidly growing communities in the Boston region.",
  "milford": "Milford is categorized by MAPC as a Subregional Urban Center. These communities are characterized by urban-scale downtown core surrounded by more suburban residential neighborhoods. New growth takes the form of redevelopment in downtown or industrial areas, and greenfield development on the periphery.",
  "millis": "Millis is categorized by MAPC as a Maturing New England Town. These communities are characterized by a mixed-use town center surrounded by compact neighborhoods. They have a large amount of vacant developable land, and new growth comes in the form of new subdivisions. They are among the most rapidly growing communities in the Boston region.",
  "milton": "Milton is categorized by MAPC as a Established Suburb. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "nahant": "Nahant is categorized by MAPC as a Mature Suburban Town. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "natick": "Natick is categorized by MAPC as a Mature Suburban Town. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "needham": "Needham is categorized by MAPC as a Mature Suburban Town. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "newton": "Newton is categorized by MAPC as a Streetcar Suburb. These communities are characterized by village-oriented residential neighborhoods dominated by multifamily homes and smaller apartment buildings. New growth occurs through limited redevelopment and infill and expansion of existing structures. They have moderately diverse populations, stable or losing population due to decreasing household size.",
  "norfolk": "Norfolk is categorized by MAPC as a Maturing New England Town. These communities are characterized by a mixed-use town center surrounded by compact neighborhoods. They have a large amount of vacant developable land, and new growth comes in the form of new subdivisions. They are among the most rapidly growing communities in the Boston region.",
  "north-reading": "North Reading is categorized by MAPC as a Established Suburb. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "norwell": "Norwell is categorized by MAPC as a Country Suburb. These communities are characterized by very low density, with no significant town center and no compact neighborhoods. They have large amounts of vacant developable land, and new growth largely takes the form of conventional low-density subdivisions developed on vacant land. They are generally growing rapidly.",
  "norwood": "Norwood is categorized by MAPC as a Subregional Urban Center. These communities are characterized by urban-scale downtown core surrounded by more suburban residential neighborhoods. New growth takes the form of redevelopment in downtown or industrial areas, and greenfield development on the periphery.",
  "peabody": "Peabody is categorized by MAPC as a Subregional Urban Center. These communities are characterized by urban-scale downtown core surrounded by more suburban residential neighborhoods. New growth takes the form of redevelopment in downtown or industrial areas, and greenfield development on the periphery.",
  "pembroke": "Pembroke is categorized by MAPC as a Established Suburb. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "quincy": "Quincy is categorized by MAPC as a Subregional Urban Center. These communities are characterized by urban-scale downtown core surrounded by more suburban residential neighborhoods. New growth takes the form of redevelopment in downtown or industrial areas, and greenfield development on the periphery.",
  "randolph": "Randolph is categorized by MAPC as a Mature Suburban Town. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "reading": "Reading is categorized by MAPC as a Mature Suburban Town. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "revere": "Revere is categorized by MAPC as a Metropolitan Core Community. These communities are characterized by an urban environment containing a mix of apartment buildings, multifamily houses, single family houses. New growth mostly occurs through redevelopment, infill, or conversion from industrial uses to residential. The have large minority and immigrant populations.",
  "rockland": "Rockland is categorized by MAPC as a Maturing New England Town. These communities are characterized by a mixed-use town center surrounded by compact neighborhoods. They have a large amount of vacant developable land, and new growth comes in the form of new subdivisions. They are among the most rapidly growing communities in the Boston region.",
  "rockport": "Rockport is categorized by MAPC as a Maturing New England Town. These communities are characterized by a mixed-use town center surrounded by compact neighborhoods. They have a large amount of vacant developable land, and new growth comes in the form of new subdivisions. They are among the most rapidly growing communities in the Boston region.",
  "salem": "Salem is categorized by MAPC as a Subregional Urban Center. These communities are characterized by urban-scale downtown core surrounded by more suburban residential neighborhoods. New growth takes the form of redevelopment in downtown or industrial areas, and greenfield development on the periphery.",
  "saugus": "Saugus is categorized by MAPC as a Mature Suburban Town. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "scituate": "Scituate is categorized by MAPC as a Established Suburb. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "sharon": "Sharon is categorized by MAPC as a Established Suburb. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "sherborn": "Sherborn is categorized by MAPC as a Country Suburb. These communities are characterized by very low density, with no significant town center and no compact neighborhoods. They have large amounts of vacant developable land, and new growth largely takes the form of conventional low-density subdivisions developed on vacant land. They are generally growing rapidly.",
  "somerville": "Somerville is categorized by MAPC as a Metropolitan Core Community. These communities are characterized by an urban environment containing a mix of apartment buildings, multifamily houses, single family houses. New growth mostly occurs through redevelopment, infill, or conversion from industrial uses to residential. The have large minority and immigrant populations.",
  "southborough": "Southborough is categorized by MAPC as a Established Suburb. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "stoneham": "Stoneham is categorized by MAPC as a Mature Suburban Town. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "stoughton": "Stoughton is categorized by MAPC as a Mature Suburban Town. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "stow": "Stow is categorized by MAPC as a Country Suburb. These communities are characterized by very low density, with no significant town center and no compact neighborhoods. They have large amounts of vacant developable land, and new growth largely takes the form of conventional low-density subdivisions developed on vacant land. They are generally growing rapidly.",
  "sudbury": "Sudbury is categorized by MAPC as a Established Suburb. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "swampscott": "Swampscott is categorized by MAPC as a Mature Suburban Town. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "topsfield": "Topsfield is categorized by MAPC as a Country Suburb. These communities are characterized by very low density, with no significant town center and no compact neighborhoods. They have large amounts of vacant developable land, and new growth largely takes the form of conventional low-density subdivisions developed on vacant land. They are generally growing rapidly.",
  "wakefield": "Wakefield is categorized by MAPC as a Mature Suburban Town. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "walpole": "Walpole is categorized by MAPC as a Maturing New England Town. These communities are characterized by a mixed-use town center surrounded by compact neighborhoods. They have a large amount of vacant developable land, and new growth comes in the form of new subdivisions. They are among the most rapidly growing communities in the Boston region.",
  "waltham": "Waltham is categorized by MAPC as a Streetcar Suburb. These communities are characterized by village-oriented residential neighborhoods dominated by multifamily homes and smaller apartment buildings. New growth occurs through limited redevelopment and infill and expansion of existing structures. They have moderately diverse populations, stable or losing population due to decreasing household size.",
  "watertown": "Watertown is categorized by MAPC as a Streetcar Suburb. These communities are characterized by village-oriented residential neighborhoods dominated by multifamily homes and smaller apartment buildings. New growth occurs through limited redevelopment and infill and expansion of existing structures. They have moderately diverse populations, stable or losing population due to decreasing household size.",
  "wayland": "Wayland is categorized by MAPC as a Established Suburb. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "wellesley": "Wellesley is categorized by MAPC as a Established Suburb. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "wenham": "Wenham is categorized by MAPC as a Maturing New England Town. These communities are characterized by a mixed-use town center surrounded by compact neighborhoods. They have a large amount of vacant developable land, and new growth comes in the form of new subdivisions. They are among the most rapidly growing communities in the Boston region.",
  "weston": "Weston is categorized by MAPC as a Established Suburb. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "westwood": "Westwood is categorized by MAPC as a Established Suburb. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "weymouth": "Weymouth is categorized by MAPC as a Mature Suburban Town. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "wilmington": "Wilmington is categorized by MAPC as a Established Suburb. These communities are characterized by owner-occupied single family homes on lots less than one acre. They contain scattered parcels of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "winchester": "Winchester is categorized by MAPC as a Mature Suburban Town. These communities are characterized by owner-occupied single family homes. They contain limited amounts of vacant developable land and new growth takes the form of infill and some redevelopment. Their population is relativley stable.",
  "winthrop": "Winthrop is categorized by MAPC as a Streetcar Suburb. These communities are characterized by village-oriented residential neighborhoods dominated by multifamily homes and smaller apartment buildings. New growth occurs through limited redevelopment and infill and expansion of existing structures. They have moderately diverse populations, stable or losing population due to decreasing household size.",
  "woburn": "Woburn is categorized by MAPC as a Subregional Urban Center. These communities are characterized by urban-scale downtown core surrounded by more suburban residential neighborhoods. New growth takes the form of redevelopment in downtown or industrial areas, and greenfield development on the periphery.",
  "wrentham": "Wrentham is categorized by MAPC as a Country Suburb. These communities are characterized by very low density, with no significant town center and no compact neighborhoods. They have large amounts of vacant developable land, and new growth largely takes the form of conventional low-density subdivisions developed on vacant land. They are generally growing rapidly.",
};
